
import { mapState, mapActions } from "vuex"

export default {
  name: "Default",
  data: () => ({
    s3Image: process.env.S3_IMAGE,
  }),
  computed: {
    ...mapState(["templateLoading"]),
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize)
  },
  methods: {
    ...mapActions(["getPayment"]),
  },
  head() {
    if (this.$route.query.product && this.chosenProduct) {
      return {
        script: [
          {
            type: "application/ld+json",
            json: {
              "@context": "http://schema.org",
              "@type": "Product",
              productId: this.$state.chosenProduct?.id,
              name: this.$state.chosenProduct?.name,
              description: this.$state.chosenProduct?.description,
              url: `https://anddine.co.uk/store/${this.$route.params.slug}/product/${this.$route.query.product}`,
              image: this.$state.chosenProduct?.image,
              offers: {
                "@type": "Offer",
                availability: "https://schema.org/InStock",
                price: this.$state.chosenProduct?.price,
                priceCurrency: "GBP",
              },
            },
          },
        ],
      }
    }
    if (this.$route.name === "store-slug" && this.chosenMaker) {
      return {
        link: [
          {
            rel: "canonical",
            href: `https://anddine.co.uk/store/${this.$route.params.slug}`,
          },
        ],
        title: `${this.$route.params.slug
          .replace("-and-", "-&-")
          .replace(/-/g, " ")
          .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())} from &Dine`,
        meta: [
          {
            hid: "description",
            name: "description",
            content: `Order from ${this.$route.params.slug
              .replace("-and-", "-&-")
              .replace(/-/g, " ")
              .replace(/(?:^|\s)\S/g, (a) =>
                a.toUpperCase()
              )}. Delivered by &Dine. Discover real flavours. Support independent Makers`,
          },
          {
            hid: "twitter:url",
            name: "twitter:url",
            content: `https://anddine.co.uk/store/${this.$route.params.slug}`,
          },
          {
            hid: "twitter:title",
            name: "twitter:title",
            content: `${this.$route.params.slug
              .replace("-and-", "-&-")
              .replace(/-/g, " ")
              .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())} from &Dine`,
          },
          {
            hid: "twitter:description",
            name: "twitter:description",
            content: `Order from ${this.$route.params.slug
              .replace("-and-", "-&-")
              .replace(/-/g, " ")
              .replace(/(?:^|\s)\S/g, (a) =>
                a.toUpperCase()
              )}. Delivered by &Dine. Discover real flavours. Support independent Makers`,
          },
          {
            hid: "twitter:image",
            name: "twitter:image",
            content: `https://api.anddine.co.uk/uploads/maker/${this.chosenMaker.id}/logo/600x600-logo.png`,
          },
          {
            hid: "og:site_name",
            name: "og:site_name",
            content: `Order from ${this.$route.params.slug
              .replace("-and-", "-&-")
              .replace(/-/g, " ")
              .replace(/(?:^|\s)\S/g, (a) =>
                a.toUpperCase()
              )}. Delivered by &Dine. Discover real flavours. Support independent Makers`,
          },
          { hid: "og:type", name: "og:type", content: "website" },
          {
            hid: "og:url",
            name: "og:url",
            content: `https://anddine.co.uk/store/${this.$route.params.slug}`,
          },
          {
            hid: "og:title",
            name: "og:title",
            content: `${this.$route.params.slug
              .replace("-and-", "-&-")
              .replace(/-/g, " ")
              .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())} from &Dine`,
          },
          {
            hid: "og:description",
            name: "og:description",
            content: `Order from ${this.$route.params.slug
              .replace("-and-", "-&-")
              .replace(/-/g, " ")
              .replace(/(?:^|\s)\S/g, (a) =>
                a.toUpperCase()
              )}. Delivered by &Dine. Discover real flavours. Support independent Makers`,
          },
          {
            hid: "og:image",
            name: "og:image",
            content: `https://api.anddine.co.uk/uploads/Maker/${this.chosenMaker.id}/profile/200x200-profile.webp`,
          },
          {
            hid: "og:image:secure_url",
            name: "og:image:secure_url",
            content: `https://anddine.co.uk/store/${this.$route.params.slug}`,
          },
          {
            hid: "og:image:alt",
            name: "og:image:alt",
            content: this.$route.params.slug,
          },
        ],
      }
    }
  },
}
